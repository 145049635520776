import * as React from "react";
import Seo from "../components/seo";
import Header from '../elements/Header';
import Footer from '../elements/Footer';
import Newsletter from '../elements/Newsletter';
import { graphql } from 'gatsby';
import { mdiDomain, mdiEmail, mdiTwitter, mdiYoutube } from '@mdi/js';
import Icon from '@mdi/react';
import hubert from './../images/hubert.jpg';
import { SEO } from '../components/SeoNew';
import Navigation from "../components/Navigation"
import { PageContainer } from "../components/PageContainer"
import { Container } from "../components/Container"

const ContactPage = () => (
  <>
    <Seo title="Contact"/>
    <PageContainer>
      <Navigation />
      <Container className="mt-16 mb-16">
        <h1
          className="font-display text-5xl sm:text-7xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] ">
          Let’s talk about your project.
        </h1>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Thank you for considering our software house for your digital needs. We understand that you may have questions
          about our services or need more information about our solutions, and we are here to help. We strive to respond
          to all inquiries as quickly as possible.
        </p>
        <div className="mt-16 flex flex-col gap-16 sm:gap-y-20 lg:flex-row">
          <form action="https://app.formyo.com/api/form/1eea653f-e5b2-6d8e-a211-052ddb4ceded" method="POST"
                className="lg:flex-auto">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900">
                  Name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="full-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ndna sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                  Company
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="company"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ndna sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div>
                  <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                    E-mail
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 mb-5 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ndna sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ndna sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="block w-full rounded-md bg-ndna px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-ndna-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ndna"
              >
                Let’s talk
              </button>
            </div>
            {/*<p className="mt-4 text-sm leading-6 text-gray-500">*/}
            {/*  By submitting this form, I agree to the{' '}*/}
            {/*  <a href="#" className="font-semibold text-ndna">*/}
            {/*    privacy&nbsp;policy*/}
            {/*  </a>*/}
            {/*  .*/}
            {/*</p>*/}
          </form>
          <div className="lg:mt-6 lg:w-80 lg:flex-none">
            <img className="h-12 w-auto" src="https://tailwindui.com/img/logos/workcation-logo-ndna.svg"
                 alt="" />
            <figure className="mt-10">
              <blockquote className="text-lg font-semibold leading-8 text-gray-900">
                <p>
                  “Let's turn your software ideas into reality.”
                </p>
              </blockquote>
              <figcaption className="mt-10 flex gap-x-6">
                <img
                  src={hubert}
                  alt="Hubert Rządziński"
                  className="h-12 w-12 flex-none rounded-full bg-gray-50"
                />
                <div>
                  <div className="text-base font-semibold text-gray-900">Hubert Rządziński</div>
                  <div className="text-sm leading-6 text-gray-600">Founder of ndna</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </Container>
    </PageContainer>
    <Footer />
  </>
  )
;

export default ContactPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <>
  <SEO title="Contact" />
</>
